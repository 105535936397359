@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Copyright © 2019-2022 DXC. All rights reserved.
 */
:root {
  /* Colors: */
  --unnamed-color-000000: #000000;
  --unnamed-color-63666a: #63666a;
  --unnamed-color-00a3e1: #00a3e1;

  /* Font/text values */
  --unnamed-font-family-arial: Arial;
  --unnamed-font-family-open-sans: Open Sans;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-18: 18px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-14: 14px;
  --unnamed-line-spacing-30: 30px;
}
body {
  background-color: white;
  text-align: center;
}
h1 {
  color: black;
  font-family: Arial;
  text-align: center;
}
div.button {
  padding: 10px;
}
div.centeredText {
  margin: auto;
  text-align: justify;
}
div.tab-pane {
  font-family: HelveticaNeue;
  color: #303232;
  border: 1px solid #329ad6;
  padding: 5px 20px 20px 20px;
  margin: auto;
  max-width: 1000px;
  text-align: left;
  display: 'none';
}
div.user-form {
  border: 1px solid #2a5c91;
  padding: 20px;
  margin: auto;
}
button {
  background-color: #ffffff;
  color: #2a5c91;
  font-family: HelveticaNeue;
  text-align: center;
  padding: 10px 20px;
  border: 2px solid #2a5c91;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
}
a.nav-tabs {
  background-color: #ffffff;
  color: #329ad6;
  font-family: HelveticaNeue;
  text-align: center;
  padding: 14px 25px;
  border: 2px solid #329ad6;
  text-decoration: none;
  display: inline-block;
}
a.nav-tabs:hover:not(.active) {
  background-color: #329ad6;
  color: white;
  border: 2px solid #329ad6;
}
.active {
  background-color: #329ad6;
  color: white;
  border: 2px solid #329ad6;
}
#introPara {
  color: #303232;
  font-family: HelveticaNeue;
  border: 1px solid #329ad6;
  padding: 20px;
  margin: auto;
  max-width: 1000px;
  text-align: justify;
  font-size: 0.9em;
}

.hero {
  background-image: url('./assets/AdobeStock_356467219_760opacity40.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
#statusNotAuth {
  color: #d65628;
  font-family: HelveticaNeue;
  border-radius: 5px;
  padding: 10px;
  margin: auto;
  max-width: 600px;
  text-align: center;
  font-size: 1em;
}
#statusAuth {
  color: #d65628;
  font-family: HelveticaNeue;
  border-radius: 5px;
  padding: 10px;
  margin: auto;
  max-width: 600px;
  text-align: center;
  font-size: 1em;
}
#startButtons {
  text-align: center;
}
div.signedOut {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-16) / 18px var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal bold 16px/18px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 15%;
}
/*#signUpButton {
	background-color: #329AD6;
	color: white;
	font-family: HelveticaNeue;
	text-align: center;
	padding: 14px 25px;
	border: 2px solid #329AD6;
	text-decoration: none;
	display: inline-block;
}
#signInButton {
	background-color: #FFFFFF;
	color: #329AD6;
	font-family: HelveticaNeue;
	text-align: center;
	padding: 14px 25px;
	border: 2px solid #329AD6;
	text-decoration: none;
	display: inline-block;
}*/
/* ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #303232;
} */

ul.nav-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
}

/* li {
    float: left;
} */

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: HelveticaNeue;
}

li a:hover:not(.active) {
  background-color: #ffffff;
  color: #2a5c91;
}

.active {
  background-color: #303232;
  color: white;
}

li.nav-tabs a {
  color: #329ad6;
  font-family: HelveticaNeue;
  text-align: center;
  padding: 14px 25px;
  border: 2px solid #329ad6;
  text-decoration: none;
  display: inline-block;
}

li.nav-tabs a:hover:not(.active) {
  background-color: #329ad6;
  color: #ffffff;
}
.active {
  background-color: #329ad6;
  color: #ffffff;
}

p.input-text {
  max-width: 800px;
  text-align: left;
}

p.text-icon {
  float: right;
  padding: 4px;
  color: #2a5c91;
  font-family: HelveticaNeue;
  font-size: 1.4em;
  cursor: pointer;
  background-color: #ffffff;
}
p.text-icon:hover:not(.active) {
  background-color: #2a5c91;
  color: #ffffff;
}
.active {
  background-color: #2a5c91;
  color: #ffffff;
}

input.user-in {
  font-family: HelveticaNeue;
  width: 500px;
  text-align: left;
  border: 1px solid #329ad6;
  padding: 5px;
  font-size: 1em;
}

body,
html {
  opacity: 1;
  padding: 0;
  margin: 0;
}
div.fullbgc {
  background: #efefef 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  background-size: cover;
  background-position-x: 100%;
  min-height: 700px;
}
div.flex {
  display: flex;
}
div.overlay {
  max-width: 980pt;
  width: 100%;
  min-height: 500pt;
  opacity: 1;
  margin: auto;
  position: absolute;
}
div.vcenter {
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
}
@media (-webkit-min-device-pixel-ratio: 1) {
  div.vcenter {
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    -ms-transform: scale(0.85) translate(-50%, -50%);
    transform: scale(0.85) translate(-50%, -50%);
  }
  div.fullbgc {
    min-height: 700px;
  }
  .loginContainer .MuiInputBase-input {
    font-size: 19px;
  }
  .loginContainer .MuiInputLabel-outlined {
    transform: translate(14px, 21px) scale(1);
  }
}
@media (-webkit-min-device-pixel-ratio: 1.25) {
  div.vcenter {
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    -ms-transform: scale(0.7) translate(-50%, -50%);
    transform: scale(0.7) translate(-50%, -50%);
  }
  div.fullbgc {
    min-height: 600px;
  }
  .loginContainer .MuiInputBase-input {
    font-size: 23px;
  }
  .loginContainer .MuiInputLabel-outlined {
    transform: translate(14px, 23px) scale(1);
  }
}
@media (-webkit-min-device-pixel-ratio: 1.5) {
  div.vcenter {
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    -ms-transform: scale(0.62) translate(-50%, -50%);
    transform: scale(0.62) translate(-50%, -50%);
  }
  div.fullbgc {
    min-height: 500px;
  }
  .loginContainer .MuiInputBase-input {
    font-size: 26px;
  }
  .loginContainer .MuiInputLabel-outlined {
    transform: translate(14px, 26px) scale(1);
  }
}
@media (-webkit-min-device-pixel-ratio: 1.75) {
  div.vcenter {
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    -ms-transform: scale(0.63) translate(-50%, -50%);
    transform: scale(0.63) translate(-50%, -50%);
  }
  .loginContainer .MuiInputBase-input {
    font-size: 26px;
  }
  .loginContainer .MuiInputLabel-outlined {
    transform: translate(14px, 30px) scale(1);
  }
}
div.dxc {
  background: transparent url('./assets/Path_30.png') 0% 0% no-repeat
    padding-box;
  opacity: 1;
  float: right;
  width: 75pt;
  height: 41pt;
  background-size: cover;
  position: absolute;
  bottom: 4%;
  left: 2%;
}
div.rounded {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0pt 3pt 6pt #00000029;
  border-radius: 16pt;
  overflow: auto;
}
div.fullbg {
  background: transparent url('./assets/AdobeStock_356467219.png') 0% 0%
    no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position-x: 100%;
}
div.forgot_password_overlay {
  max-width: 600pt;
  width: 100%;
  overflow: auto;
}
div.forgot_password_overlay.step2 {
  max-width: 600pt;
}
div.forgot_password_overlay.step3 {
  max-width: 600pt;
  width: 100%;
}
div.forgot_password_overlay .title {
  font: var(--unnamed-font-style-normal) normal bold 18pt/21pt
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 18pt/21pt Arial;
  letter-spacing: 0pt;
  color: #666666;
  opacity: 1;
  padding: 4% 4% 2% 4%;
}
div.forgot_password_overlay .step {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-18) var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 16pt/18pt Arial;
  letter-spacing: 0pt;
  color: #666666;
  padding: 2% 4% 2% 4%;
}
div.forgot_password_overlay .step b {
  font: var(--unnamed-font-style-normal) normal bold 20pt /
    var(--unnamed-line-spacing-18) var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 20pt/18pt Arial;
  letter-spacing: 0pt;
  color: #000000;
}
div.forgot_password_overlay .temporary {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-18) var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 16pt/18pt Arial;
  letter-spacing: 0pt;
  color: #000000;
  opacity: 1;
  padding: 2% 4% 2% 4%;
}
div.forgot_password_overlay .verify {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-18) var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 16pt/18pt Arial;
  letter-spacing: 0pt;
  color: #000000;
  opacity: 1;
  padding: 2% 4% 2% 4%;
}
div.never {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-18) var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 16pt/18pt Arial;
  letter-spacing: 0px;
  opacity: 1;
  padding: 2% 4% 2% 4%;
  width: 540pt;
}
div.never.half {
  width: 360pt;
  margin: auto;
}
div.never.right_login b {
  color: red;
}
div.never.right_login a {
  color: #00a3e1;
  text-decoration: none;
}
div.never.right_login a:hover {
  text-decoration: underline;
}
div.forgot_password_overlay .emailLabel {
  font: var(--unnamed-font-style-normal) normal bold 14pt/16pt
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 14pt/16pt Arial;
  letter-spacing: 0pt;
  color: #666666;
  opacity: 1;
  padding: 2% 4% 2% 4%;
  width: 540pt;
}
div.forgot_password_overlay .emailLabel b {
  font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-18) var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 16pt/18pt Arial;
  letter-spacing: 0pt;
  color: #000000;
  opacity: 1;
}
div.error {
  color: #aa0000;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 14pt/16pt var(--unnamed-font-family-arial);
  font: normal normal normal 14pt/16pt Arial;
}
div.forgot_password_overlay .password_help {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 6pt;
  opacity: 1;
  padding: 2%;
  margin: 2% 4% 2% 4%;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 14pt/16pt var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 14pt/16pt Arial;
  letter-spacing: 0pt;
  color: #666666;
  opacity: 1;
}
div.forgot_password_overlay .password_help b {
  font: var(--unnamed-font-style-normal) normal bold 14pt/16pt
    var(--unnamed-font-family-arial);
  font: normal normal bold 14pt/16pt Arial;
}
div.never a {
  color: #00a3e1;
  text-decoration: none;
}
div.never a:hover {
  text-decoration: underline;
}

div.forgot_password_overlay .head {
  text-align: center;
}
.login_container .MuiPaper-root {
  padding: 2%;
}
div.forgot_password_overlay .left_login,
div.forgot_password_overlay .right_login,
div.overlay .right_login .right_login,
div.overlay .right_login .left_login {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) / 18pt
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 16pt/18pt Arial;
  letter-spacing: 0px;
  color: var(--unnamed-color-00a3e1);
  color: #00a3e1;
  opacity: 1;
  margin: 2% 4% 2% 4%;
  float: left;
  padding: 2%;
  text-transform: none;
}
div.forgot_password_overlay .right_login,
div.overlay .right_login .right_login {
  color: white;
}
.fa-check-circle {
  font-size: 60pt;
  color: green;
  padding: 4%;
}
div.overlay Button.right_login {
  background: #5f249f 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  color: white;
}
div.successIcon {
  width: 44px;
  height: 44px;
  background: url('./assets/success.svg') no-repeat center center / contain;
  margin: 60px auto 25px auto;
}
div.forgot_password_overlay .passwordChanged {
  font: var(--unnamed-font-style-normal) normal bold 18pt/21pt
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 18pt/21pt Arial;
  letter-spacing: 0pt;
  color: #000000;
  opacity: 1;
  padding: 1% 4% 1% 4%;
  width: 540pt;
}
div.forgot_password_overlay .passwordChangedSub {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) / 18pt
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-63666a);
  text-align: center;
  font: normal normal normal 16pt/18pt Arial;
  letter-spacing: 0px;
  color: #63666a;
  opacity: 1;
}
div.forgot_password_overlay .right_login {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) / 21pt
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 18pt/21pt Arial;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  width: 129pt;
  float: right;
}
div.forgot_password_overlay .right_login.center {
  margin: 5% auto;
  float: none;
  width: 146pt;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) / 21px
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 18px/21px Arial;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 2%;
}
div .head .text {
  font: var(--unnamed-font-style-normal) normal 600 32pt/43pt
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal 600 32pt/43pt Open Sans;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
  padding: 5pt;
}
div.forgot_password_overlay .head .text {
  font: var(--unnamed-font-style-normal) normal 600 22pt /
    var(--unnamed-line-spacing-30) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  font: normal normal 600 22pt/30pt Open Sans;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}
.footer {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 14px / var(--unnamed-line-spacing-17)
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: right;
  font: normal normal normal 14px/17px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}
div.left_login {
  width: 58%;
  min-height: 500pt;
  height: 100%;
  background: transparent url('./assets/AdobeStock_356467219_760.png') 0% 0%
    no-repeat padding-box;
  background-size: cover;
  background-position-x: 55%;
  border-radius: 16pt 0pt 0pt 16pt;
  opacity: 1;
  display: inline-block;
  vertical-align: top;
}
div.left_login .head {
  padding: 4% 4% 1% 4%;
  text-align: left;
}
div.forgot_password_overlay .head {
  padding: 2%;
}
div.right_login {
  max-width: 420pt;
  width: 42%;
  display: inline-block;
  vertical-align: top;
}
div.right_login .label {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-16) / 18pt var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal bold 16pt/18pt Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 4% 4% 1% 4%;
}
div.right_login .inputText {
  margin: 4% 4% 1% 4%;
  width: 90%;
  text-transform: none;
}
div.forgot_password_overlay .inputText {
  margin: 1% 4% 1% 4%;
  width: 540pt;
  text-transform: none;
}
div .submit {
  background: #5f249f 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  margin: 4% 4% 1% 4%;
  width: 90%;
  padding: 4%;

  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) / 21pt
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 18pt/21pt Arial;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-transform: capitalize;
  height: 50pt;
}
div .submit.MuiButton-contained.Mui-disabled {
  background: #5f249f 0% 0% no-repeat padding-box;
  background-color: #5f249f;
  opacity: 0.5;
  color: #ffffff;
}

div .resendCodeNotification {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 14pt/16pt var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-63666a);
  text-align: left;
  font: normal normal normal 14pt/16pt Arial;
  letter-spacing: 0pt;
  color: #63666a;
  opacity: 1;
  padding: 3% 4%;
}

div.right_login .submit.emergency {
  border: 1px solid #5f249f;
  opacity: 1;
  background: white;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) / 21pt
    var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 18pt/21pt Arial;
  letter-spacing: 0px;
  color: #5f249f;
  opacity: 1;
}
div.forgot_password {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-18) var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 16pt/18pt Arial;
  letter-spacing: 0pt;
  color: #00a3e1;
  opacity: 1;
  width: 90%;
  margin: 4% 4% 1% 4%;
}
div.grp164 {
  width: 47pt;
  height: 42pt;
  background: transparent url('./assets/Group_164.png') 0% 0% no-repeat
    padding-box;
  background-position: center;
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
  padding: 1pt;
}
div.forgot_password_overlay .grp164 {
  width: 41pt;
  height: 31pt;
}

div.subhead {
  width: 80%;
  height: 32pt;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 14pt/18pt var(--unnamed-font-family-arial);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-63666a);
  text-align: left;
  font: normal normal normal 14pt/18pt Arial;
  letter-spacing: 0px;
  color: #63666a;
  opacity: 1;
  padding: 1% 4%;
}
.heading {
  background-color: black;
  text-align: center;
  color: white;
  font-size: larger;
  height: 3rem;
  display: flex;
}
.heading span {
  align-self: flex-end;
}
a.hover {
  text-decoration: none;
}

a.hover:hover {
  text-decoration: underline;
  cursor: pointer;
  /* font-weight: bold; */
}
.row:after {
  content: '';
  display: table;
  clear: both;
}
.bluerectangle {
  height: 2px;
  width: 50px;
  background-color: #146de0;
}
.greyrectangle {
  height: 2px;
  width: 50px;
  background-color: #8e9aaa;
}

.tableform1 {
  width: 35%;
  height: 300px;
  margin-left: 33.3%;
  /* margin-top: 50px; */
  border: 2px solid rgb(8, 8, 8);
  padding: 20px;
  text-align: center;
  font: 14px Arial;
  /* font-weight: bold; */
  background-color: white;
}
.tableform {
  width: 33.3%;
  margin-left: 33.3%;
  /* margin-top: 50px; */
  border: 2px solid rgb(8, 8, 8);
  /* padding: 20px; */
  text-align: center;
  font: 14px Arial;
  /* font-weight: bold; */
  z-index: -1;
  position: absolute;
  background-color: white;
}
.tableformfirst {
  width: 33.3%;
  margin-left: 33.3%;
  /* margin-top: 2%; */
  border: 2px solid rgb(8, 8, 8);
  padding: 20px;
  text-align: center;
  font: 14px Arial;
  /* font-weight: bold; */
  z-index: -1;
  position: absolute;
  background-color: white;
}

.tableformlast {
  width: 33.3%;
  margin-left: 33.3%;
  /* margin-top: 50px; */
  border: 2px solid rgb(8, 8, 8);
  /* padding: 20px; */
  text-align: center;
  font: 14px Arial;
  /* font-weight: bold; */
  z-index: -1;
  position: absolute;
  background-color: white;
}
.table {
  padding: 20px;
}
.trstyle {
  height: 40px;
}

.signinbutton {
  height: 35px;
  width: 180px;
  padding: 5px 0;
  background-color: #feed00;
  border: 1px solid black;
  border-radius: 1px;
  font: 14px Arial;
  font-weight: bold;
  color: black;
  margin-top: 30px;
}
.cancelbutton {
  height: 35px;
  width: 180px;
  padding: 5px 0;
  background-color: lightgrey;
  border: 1px solid white;
  border-radius: 1px;
  font: 14px Arial;
  font-weight: bold;
  margin-top: 30px;
  color: black;
}
.firstbutton {
  height: 35px;
  width: 50px;
  background-color: #feed00;
  border: 0.1rem solid black;
  font-weight: bold;
  font-size: 20px;
}
.forgotpwdbutton {
  height: 35px;
  width: 180px;
  padding: 0px 0;
  background-color: white;
  color: red;
  border: 0.1rem solid white;
  font: 14px Arial;
  font-weight: bold;
  margin-top: 30px;
}

/* The popup form - hidden by default */
.form-popup {
  display: none;
  /* position: fixed; */
  /* bottom: 0; */
  /* right: 15px; */
  width: 30%;
  border: 3px solid black;
  z-index: 1;
  margin-left: 530px;
  margin-top: 50px;
  padding: 5px;
  text-align: center;
  font: 14px Arial;
  background-color: #e5e8e8;
}

/* Add styles to the form container */
.form-container {
  /* max-width: 500px; */
  width: 90%;
  padding: 5px;
  background-color: rgb(229, 231, 218);
}

/* Full-width input fields */
.form-container input[type='text'],
.form-container input[type='password'] {
  width: 100%;
  padding: 5px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type='text']:focus,
.form-container input[type='password']:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

.loading {
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-top: 0px;
  border: 2px solid rgb(8, 8, 8);
  padding: 0px;
  text-align: center;
  font: 14px Arial;
  font-weight: bold;
  z-index: -1;
  position: absolute;
  background-color: black;
}
